import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      Hi, I'm <Text sx={styles.author}>Aby Thannikal Joseph</Text>
    </Heading>
    <Heading variant='h2' sx={styles.occupation}>
      👨‍💻Test Automation Engineer
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Passionate about all things Cloud and DevOps! ✨
    </Heading>
    <Box variant='buttons.group'>
	{
	 /**
	  * <Button as={Link} to='/contact'>
      *   Contact Me
      * </Button>
	  */
	}
      <Button as={Link} to='/about-me'>
        About Me
      </Button>
    </Box>
  </>
)
